import React from "react";

// Dashboard
import Dashboard from "views/dashboard/Dashboard";
import StoneForm from "views/pim/stone/stones/stoneForm";

// Access
const Roles = React.lazy(() => import("views/access/roles/Roles"));
const RoleForm = React.lazy(() => import("views/access/roles/RoleForm"));
const UserLocation = React.lazy(() => import("views/access/userLocation/UserLocation"));
const UserLocationForm = React.lazy(() => import("views/access/userLocation/UserLocationForm"));
const Users = React.lazy(() => import("views/access/users/Users"));
const UserForm = React.lazy(() => import("views/access/users/UserForm"));
const RolePermissions = React.lazy(() => import("views/access/role-persmission/RolePermission"));
const RolePermissionForm = React.lazy(() => import("views/access/role-persmission/RolePermissionForm"));
const UserRole = React.lazy(() => import("views/access/user-role/UserRole"));
const UserRoleForm = React.lazy(() => import("views/access/user-role/UserRoleForm"));
const Permission = React.lazy(() => import("views/access/permission/Permission"));
const PermissionForm = React.lazy(() => import("views/access/permission/PermissionForm"));
const UserCalendar = React.lazy(() => import("views/access/userCalendar/userCalendar"));

//Orders Management
const Orders = React.lazy(() => import("views/orders/orders/orders"));
const OrderVersion = React.lazy(() => import("views/orders/orders/orderVersion"));
const OrdersWizard = React.lazy(() => import("views/orders/orders/ordersWizard"));
const OrdersExternalState = React.lazy(() => import("views/orders/orders-externalstate/ordersExternalState"));
const OrdersExternalStateForm = React.lazy(() => import("views/orders/orders-externalstate/ordersExternalStateForm"));
const OrdersInternalState = React.lazy(() => import("views/orders/orders-internalstate/ordersInternalState"));
const OrdersInternalStateForm = React.lazy(() => import("views/orders/orders-internalstate/orderInternalStateForm"));
const OrdersLineItemExternalState = React.lazy(() => import("views/orders/orders-lineitemexternalstate/ordersLineItemExternalState"));
const OrdersLineItemExternalStateForm = React.lazy(() => import("views/orders/orders-lineitemexternalstate/ordersLineItemExternalStateForm"));
const OrdersLineItemInternalState = React.lazy(() => import("views/orders/orders-lineiteminternalstate/ordersLineItemInternalState"));
const OrdersLineItemInternalStateForm = React.lazy(() => import("views/orders/orders-lineiteminternalstate/ordersLineItemInternalStateForm"));
const OrderLineItem = React.lazy(() => import("views/orders/order-line-item/orderLineItem"));
const OrderLineItemVersionHistory = React.lazy(() => import("views/orders/order-line-item/orderLineItemVersionHistory"));
const orderLineItemForm = React.lazy(() => import("views/orders/order-line-item/orderLineItemForm"));
const StockReconciliationStates = React.lazy(() => import("views/orders/reconciliation/stock-reconciliation-states/stockReconciliationStates"));
const StockReconciliationStatesForm = React.lazy(() => import("views/orders/reconciliation/stock-reconciliation-states/stockReconciliationStatesForm"));
const InventoryTagReconciliationStates = React.lazy(() => import("views/orders/reconciliation/inventory-tag-reconciliation-states/inventoryTagReconciliationStates"));
const InventoryTagReconciliationStatesForm = React.lazy(() => import("views/orders/reconciliation/inventory-tag-reconciliation-states/inventoryTagReconciliationStatesForm"));
const ReconciliationType = React.lazy(() => import("views/orders/reconciliation/reconcilation-type/reconcilationType"));
const ReconciliationTypeForm = React.lazy(() => import("views/orders/reconciliation/reconcilation-type/reconcilationTypeForm"));
const ReconciliationDetails = React.lazy(() => import("views/orders/reconciliation/reconcilation-details/reconcilationDetails"));
const ReconciliationDetailsForm = React.lazy(() => import("views/orders/reconciliation/reconcilation-details/reconcilationDetailsForm"));
const StockReconcilation = React.lazy(() => import("views/orders/reconciliation/stock-reconciliation/StockReconciliation"));
const StockReconcilationForm = React.lazy(() => import("views/orders/reconciliation/stock-reconciliation/StockReconciliationForm"));
const InventoryTagReconciliation = React.lazy(() => import("views/orders/reconciliation/inventory-tag-reconciliation/inventoryTagReconciliation"));
const InventoryTagReconciliationForm = React.lazy(() => import("views/orders/reconciliation/inventory-tag-reconciliation/inventoryTagReconciliationForm"));
const FullProductState = React.lazy(() => import("views/procurement/demandStates/fullProductState/fullProductState"));
const FullProductStateForm = React.lazy(() => import("views/procurement/demandStates/fullProductState/fullProductStateForm"));
const StockProductStates = React.lazy(() => import("views/procurement/demandStates/stockProductStates/stockProductStates"));
const StockProductStatesForm = React.lazy(() => import("views/procurement/demandStates/stockProductStates/StockProductStatesForm"));
const VendorProductState = React.lazy(() => import("views/procurement/demandStates/vendorProductState/vendorProductState"));
const VendorProductStateForm = React.lazy(() => import("views/procurement/demandStates/vendorProductState/vendorProductStateForm"));
const ServiceProductState = React.lazy(() => import("views/procurement/demandStates/serviceProductState/serviceProductState"));
const ServiceProductStateForm = React.lazy(() => import("views/procurement/demandStates/serviceProductState/serviceProductStateForm"));

const MetalState = React.lazy(() => import("views/procurement/demandStates/metalState/metalState"));
const MetalStateForm = React.lazy(() => import("views/procurement/demandStates/metalState/metalStateForm"));

const AddOnState = React.lazy(() => import("views/procurement/demandStates/addOnState/addOnState"));
const AddOnStateForm = React.lazy(() => import("views/procurement/demandStates/addOnState/addOnStateForm"));

const FindingState = React.lazy(() => import("views/procurement/demandStates/findingState/findingState"));
const FindingStateForm = React.lazy(() => import("views/procurement/demandStates/findingState/findingStateForm"));

const ComponentState = React.lazy(() => import("views/procurement/demandStates/componentState/componentState"));
const ComponentStateForm = React.lazy(() => import("views/procurement/demandStates/componentState/componentStateForm"));


//Gift Voucher
const GiftVoucher = React.lazy(() => import("views/orders/gift-voucher/giftVoucher"));
const GiftVoucherVersionHistory = React.lazy(() => import("views/orders/gift-voucher/giftVoucherVersionHistory"));
const GiftVoucherForm = React.lazy(() => import("views/orders/gift-voucher/giftVoucherForm"));

const FraudScore = React.lazy(() => import("views/orders/fraud-score/fraudScore"));

// UserCoupon
const UserCoupon = React.lazy(() => import("views/orders/user-coupon/userCoupon"));
const UserCouponForm = React.lazy(() => import("views/orders/user-coupon/userCouponForm"));


//PIM
// metals
const Metals = React.lazy(() => import("views/pim/metal/metals/metals"));
const MetalsVersionHistory = React.lazy(() => import("views/pim/metal/metals/metalsVersionHistory"));
const MetalForm = React.lazy(() => import("views/pim/metal/metals/metalForm"));
const MetalUnits = React.lazy(() => import("views/pim/metal/metal-units/metalUnits"));
const MetalUnitsForm = React.lazy(() => import("views/pim/metal/metal-units/metalUnitsForm"));
const MetalTypes = React.lazy(() => import("views/pim/metal/metal-types/metalTypes"));
const MetalTypesForm = React.lazy(() => import("views/pim/metal/metal-types/metalTypesForm"));
const MetalsTypesVersionHistory = React.lazy(() => import("views/pim/metal/metal-types/metalsTypesVersionHistory"));
const MetalPropertiesVersionHistory = React.lazy(() => import("views/pim/metal/metal-properties/metalPropertiesVersionHistory"));

const MetalUnitsVersionHistory = React.lazy(() => import("views/pim/metal/metal-units/metalUnitsVersionHistory"));
const MetalProperties = React.lazy(() => import("views/pim/metal/metal-properties/metalProperties"));
const MetalPropertiesForm = React.lazy(() => import("views/pim/metal/metal-properties/metalPropertiesForm"));
const MetalFormBulkUpdates = React.lazy(() => import("views/pim/metal/metals/bulkUpdates/bulkUpdatesMetalForm"));


const Finish = React.lazy(() => import("views/pim/metal/finish/finish"));
const FinishForm = React.lazy(() => import("views/pim/metal/finish/finishForm"));
const FinishVersionHistory = React.lazy(() => import("views/pim/metal/finish/finishVersionHistory"));

// stones
const StoneUnits = React.lazy(() => import("views/pim/stone/stone-units/stoneUnits"));
const StoneUnitsForm = React.lazy(() => import("views/pim/stone/stone-units/stoneUnitsForm"));
const StoneTypes = React.lazy(() => import("views/pim/stone/stone-types/stoneTypes"));
const StoneTypesForm = React.lazy(() => import("views/pim/stone/stone-types/stoneTypesForm"));
const StoneProperty = React.lazy(() => import("views/pim/stone/stone-property/stoneProperty"));
const StonePropertyForm = React.lazy(() => import("views/pim/stone/stone-property/stonePropertyForm"));
const Stones = React.lazy(() => import("views/pim/stone/stones/stones"));
const StoneFormBulkUpdates = React.lazy(() => import("views/pim/stone/stones/bulkUpdates/bulkUpdatesStoneForm"));
const StoneSettings = React.lazy(() => import("views/pim/stone/settings/settings"));
const StoneSettingsForm = React.lazy(() => import("views/pim/stone/settings/settingsForm"));
const StoneSettingsVersionHistory = React.lazy(() => import("views/pim/stone/settings/settingsVersionHistory"));
const Position = React.lazy(() => import("views/pim/stone/position/position"));
const PositionForm = React.lazy(() => import("views/pim/stone/position/positionForm"));
const PositionVersionHistory = React.lazy(() => import("views/pim/stone/position/positionVersionHistory"));
const StonesVersionHistory = React.lazy(() => import("views/pim/stone/stones/stonesVersionHistory"));
const StoneTypesVersionHistory = React.lazy(() => import("views/pim/stone/stone-types/stoneTypesVersionHistory"));
const StonePropertiesVersionHistory = React.lazy(() => import("views/pim/stone/stone-property/stonePropertiesVersionHistory"));
const StoneUnitsVersionHistory = React.lazy(() => import("views/pim/stone/stone-units/stoneUnitsVersionHistory"));

// Service
const ServiceUnits = React.lazy(() => import("views/pim/service/service-units/serviceUnits"));
const ServiceUnitsForm = React.lazy(() => import("views/pim/service/service-units/serviceUnitsForm"));
const ServiceTypes = React.lazy(() => import("views/pim/service/service-types/serviceTypes"));
const ServiceTypesForm = React.lazy(() => import("views/pim/service/service-types/serviceTypesForm"));
const ServiceProperties = React.lazy(() => import("views/pim/service/service-properties/serviceProperties"));
const ServicePropertiesForm = React.lazy(() => import("views/pim/service/service-properties/servicePropertiesForm"));
const Services = React.lazy(() => import("views/pim/service/services/services"));
const ServiceForm = React.lazy(() => import("views/pim/service/services/serviceForm"));
const ServicesVersionHistory = React.lazy(() => import("views/pim/service/services/servicesVersionHistory"));
const ServiceTypesVersionHistory = React.lazy(() => import("views/pim/service/service-types/serviceTypesVersionHistory"));
const ServicePropertiesVersionHistory = React.lazy(() => import("views/pim/service/service-properties/servicePropertiesVersionHistory"));
const ServiceUnitsVersionHistory = React.lazy(() => import("views/pim/service/service-units/serviceUnitsVersionHistory"));
const ServicesFormBulkUpdates = React.lazy(() => import("views/pim/service/services/bulkUpdates/bulkUpdatesServicesForm"));

// Add-on Product
const AddOnUnits = React.lazy(() => import("views/pim/add-on/add-on-units/addOnUnits"));
const AddOnUnitsForm = React.lazy(() => import("views/pim/add-on/add-on-units/addOnUnitsForm"));
const AddOnTypes = React.lazy(() => import("views/pim/add-on/add-on-types/addOnTypes"));
const AddOnTypesForm = React.lazy(() => import("views/pim/add-on/add-on-types/addOnTypesForm"));
const AddOnProperties = React.lazy(() => import("views/pim/add-on/add-on-properties/addOnProperties"));
const AddOnPropertiesForm = React.lazy(() => import("views/pim/add-on/add-on-properties/addOnPropertiesForm"));
const AddOn = React.lazy(() => import("views/pim/add-on/add-on/addOn"));
const AddOnForm = React.lazy(() => import("views/pim/add-on/add-on/addOnForm"));
const AddOnVersionHistory = React.lazy(() => import("views/pim/add-on/add-on/addOnVersionHistory"));
const AddOnTypesVersionHistory = React.lazy(() => import("views/pim/add-on/add-on-types/addOnTypesVersionHistory"));
const AddOnPropertiesVersionHistory = React.lazy(() => import("views/pim/add-on/add-on-properties/addOnPropertiesVersionHistory"));
const AddOnUnitsVersionHistory = React.lazy(() => import("views/pim/add-on/add-on-units/addOnUnitsVersionHistory"));

// Family
const Family = React.lazy(() => import("views/pim/family/family/family"));
const FamilyForm = React.lazy(() => import("views/pim/family/family/familyForm"));
const FamilyTypes = React.lazy(() => import("views/pim/family/family-types/familyTypes"));
const FamilyTypesForm = React.lazy(() => import("views/pim/family/family-types/familyTypesForm"));
const FamilyVersionHistory = React.lazy(() => import("views/pim/family/family/familyVersionHistory"));
const FamilyTypesVersionHistory = React.lazy(() => import("views/pim/family/family-types/familyTypesVersionHistory"));

//components
const Component = React.lazy(() => import("views/pim/component/component/component"));
const ComponentForm = React.lazy(() => import("views/pim/component/component/componentForm"));
const ComponentFormBulkUpdates = React.lazy(() => import("views/pim/component/component/bulkUpdates/bulkUpdatesComponentForm"));
const ComponentUnits = React.lazy(() => import("views/pim/component/component-units/componentUnits"));
const ComponentUnitsForm = React.lazy(() => import("views/pim/component/component-units/componentUnitsForm"));
const ComponentTypes = React.lazy(() => import("views/pim/component/component-types/componentTypes"));
const ComponentTypesForm = React.lazy(() => import("views/pim/component/component-types/componentTypesForm"));
const ComponentProperties = React.lazy(() => import("views/pim/component/component-properties/componentProperties"));
const ComponentPropertiesForm = React.lazy(() => import("views/pim/component/component-properties/componentPropertiesForm"));
const ComponentVersionHistory = React.lazy(() => import("views/pim/component/component/componentsVersionHistory"));
const ComponentPropertiesVersionHistory = React.lazy(() => import("views/pim/component/component-properties/compoentPropertiesVersionHistory"));
const ComponentTypesVersionHistory = React.lazy(() => import("views/pim/component/component-types/componentTypesVersionHistory"));
const ComponentUnitsVersionHistory = React.lazy(() => import("views/pim/component/component-units/componentUnitsVersionHistory"));

// Finding
const Finding = React.lazy(() => import("views/pim/findings/finding/finding"));
const FindingForm = React.lazy(() => import("views/pim/findings/finding/findingForm"));
const FindingUnits = React.lazy(() => import("views/pim/findings/finding-units/findingUnits"));
const FindingUnitsForm = React.lazy(() => import("views/pim/findings/finding-units/findingUnitsForm"));
const FindingTypes = React.lazy(() => import("views/pim/findings/finding-types/findingTypes"));
const FindingTypesForm = React.lazy(() => import("views/pim/findings/finding-types/findingTypesForm"));
const FindingProperties = React.lazy(() => import("views/pim/findings/finding-properties/findingProperties"));
const FindingPropertiesForm = React.lazy(() => import("views/pim/findings/finding-properties/findingPropertiesForm"));
const FindingPropertiesVersionHistory = React.lazy(() => import("views/pim/findings/finding-properties/findingPropertiesVersionHistory"));
const FindingVersionHistory = React.lazy(() => import("views/pim/findings/finding/findingVersionHistory"));
const FindingTypesVersionHistory = React.lazy(() => import("views/pim/findings/finding-types/findingTypesVersionHistory"));
const FindingUnitsVersionHistory = React.lazy(() => import("views/pim/findings/finding-units/findingUnitsVersionHistory"));
const FindingFormBulkUpdates = React.lazy(() => import("views/pim/findings/finding/bulkUpdates/bulkUpdatesFindingForm"));

//RuleEngine
const Rules = React.lazy(() => import("views/rules/rules"));
const RuleEditor = React.lazy(() => import("views/rules/ruleEditor"));
const RuleVersion = React.lazy(() => import("views/rules/ruleVersion"));

//  Set
const ComponentSets = React.lazy(() => import("views/pim/set/componentSet/componentSet"));
const ComponentSetForm = React.lazy(() => import("views/pim/set/componentSet/componentSetForm"));
const ComponentSetUnits = React.lazy(() => import("views/pim/set/componentSet-units/componentSetUnits"));
const ComponentSetUnitsForm = React.lazy(() => import("views/pim/set/componentSet-units/componentSetUnitsForm"));
const ComponentSetProperties = React.lazy(() => import("views/pim/set/componentSet-properties/componentSetProperties"));
const ComponentSetPropertiesForm = React.lazy(() => import("views/pim/set/componentSet-properties/componentSetPropertiesForm"));
const ComponentSetPropertiesVersionHistory = React.lazy(() => import("views/pim/set/componentSet-properties/componentSetPropertiesVersionHistory"));
const ComponentSetVersionHistory = React.lazy(() => import("views/pim/set/componentSet/componentSetVersionHistory"));
const ComponentSetUnitsVersionHistory = React.lazy(() => import("views/pim/set/componentSet-units/componentSetUnitsVersionHistory"));

// Collections
const Collections = React.lazy(() => import("views/pim/collections/collections/collections"));
const CollectionsForm = React.lazy(() => import("views/pim/collections/collections/collectionsForm"));
const CollectionsTypes = React.lazy(() => import("views/pim/collections/collections-types/collectionsTypes"));
const CollectionsTypesForm = React.lazy(() => import("views/pim/collections/collections-types/collectionsTypesForm"));

// Procurement

const PrLineItem = React.lazy(() => import("views/procurement/prLineItem/prLineItem"));
const PrLineItemForm = React.lazy(() => import("views/procurement/prLineItem/prLineItemForm"));
const Po = React.lazy(() => import("views/procurement/po/po"));
const PoForm = React.lazy(() => import("views/procurement/po/poForm"));
const PoLineItem = React.lazy(() => import("views/procurement/poLineItem/poLineItem"));
const PoLineItemForm = React.lazy(() => import("views/procurement/poLineItem/poLineItemForm"));
const PoLineItemDetail = React.lazy(() => import("views/procurement/poLineItemDetail/poLineItemDetail"));
const PoLineItemDetailForm = React.lazy(() => import("views/procurement/poLineItemDetail/poLineItemDetailForm"));
const PoLineItemDetailVersionHistory = React.lazy(() => import("views/procurement/poLineItemDetail/poLineItemDetailVersionHistory"));
const OrderLineItemDemand = React.lazy(() => import("views/procurement/orderLineItemDemand/orderLineItemDemand"));
const OrderLineItemDemandForm = React.lazy(() => import("views/procurement/orderLineItemDemand/orderLineItemDemandForm"));
const OrderLineItemDemandVersionHistory = React.lazy(() => import("views/procurement/orderLineItemDemand/orderLineItemDemandVersionHistory"));
const PrLineItemVersionHistory = React.lazy(() => import("views/procurement/prLineItem/prLineItemVersionHistory"));
const PoVersionHistory = React.lazy(() => import("views/procurement/po/poVersionHistory"));
const PoLineItemVersionHistory = React.lazy(() => import("views/procurement/poLineItem/poLineItemVersionHistory"));

const PurchaseReturn = React.lazy(() => import("views/procurement/purchaseReturn/purchaseReturn"));
const PurchaseReturnForm = React.lazy(() => import("views/procurement/purchaseReturn/purchaseReturnForm"));

const Grn = React.lazy(() => import("views/procurement/grn/grn"));
const GrnForm = React.lazy(() => import("views/procurement/grn/grnForm"));
const GrnVersionHistory = React.lazy(() => import("views/procurement/grn/grnVersionHistory"));

const GrnLineItem = React.lazy(() => import("views/procurement/grnLineItem/grnLineItem"));
const GrnLineItemForm = React.lazy(() => import("views/procurement/grnLineItem/grnLineItemForm"));
const GrnLineItemVersionHistory = React.lazy(() => import("views/procurement/grnLineItem/grnLineItemVersionHistory"));
const OrderDemandLineItem = React.lazy(() => import("views/procurement/orderDemandLineItem/orderDemandLineItem"));
const OrderDemandLineItemForm = React.lazy(() => import("views/procurement/orderDemandLineItem/orderDemandLineItemForm"));
const OrderDemandLineItemVersionHistory = React.lazy(() => import("views/procurement/orderDemandLineItem/orderDemandLineItemVersionHistory"));

//Storage Management
//Stock Product
const StockProduct = React.lazy(() => import("views/storageManagement/stockProduct/stockProduct"));
const StockProductForm = React.lazy(() => import("views/storageManagement/stockProduct/stockProductForm"));
const StockProductVersionHistory = React.lazy(() => import("views/storageManagement/stockProduct/stockProductVersionHistory"));

//Stock Tag
const StockTag = React.lazy(() => import("views/storageManagement/stockTag/stockTag"));
const StockTagForm = React.lazy(() => import("views/storageManagement/stockTag/stockTagForm"));
const StockTagVersionHistory = React.lazy(() => import("views/storageManagement/stockTag/stockTagVersionHistory"));

//Inventory Tag
const InventoryTag = React.lazy(() => import("views/storageManagement/inventoryTag/inventoryTag"));
const InventoryTagForm = React.lazy(() => import("views/storageManagement/inventoryTag/inventoryTagForm"));
const InventoryTagVersionHistory = React.lazy(() => import("views/storageManagement/inventoryTag/inventoryTagVersionHistory"));

//Stock Tray
const StockTray = React.lazy(() => import("views/storageManagement/stockTray/stockTray"));
const StockTrayForm = React.lazy(() => import("views/storageManagement/stockTray/stockTrayForm"));
const StockTrayVersionHistory = React.lazy(() => import("views/storageManagement/stockTray/stockTrayVersionHistory"));
const StockTrayFormBulkUpdates = React.lazy(() => import("views/storageManagement/stockTray/bulkUpdates/bulkUpdatesStockTrayForm"));

//Inventory Tray
const InventoryTray = React.lazy(() => import("views/storageManagement/inventoryTray/inventoryTray"));
const InventoryTrayForm = React.lazy(() => import("views/storageManagement/inventoryTray/inventoryTrayForm"));
const InventoryTrayVersionHistory = React.lazy(() => import("views/storageManagement/inventoryTray/inventoryTrayVersionHistory"));

//Finance
const PettyCash = React.lazy(() => import("views/Finance/pettyCash/pettyCash"));
const PoPrice = React.lazy(() => import("views/Finance/poPrice/poPrice"));
const PoPriceForm = React.lazy(() => import("views/Finance/poPrice/poPriceForm"));
const PoPriceVersionHistory = React.lazy(() => import("views/Finance/poPrice/poPriceVersionHistory"));

// Reports
const BookReport = React.lazy(() => import("views/procurement/bookReport/bookReport"));
const HoldReport = React.lazy(() => import("views/procurement/holdReport/holdReport"));
const CSHoldReport = React.lazy(() => import("views/procurement/csHoldReport/csHoldReport"));


// Merchandising
const NavigationHierarchy = React.lazy(() =>
  import("views/merchandising/navigationHierarchy")
);
const NavigationHierarchyForm = React.lazy(() =>
  import("views/merchandising/navigationHierarchy/navigationHierarchyForm")
);

const NavigationHierarchyVersionHistory = React.lazy(() =>
  import("views/merchandising/navigationHierarchy/navigationHierarchyVersionHistory")
);

//Lists

const Lists = React.lazy(() =>
  import("views/merchandising/navigationList/index")
);
const ListForm = React.lazy(() =>
  import("views/merchandising/navigationList/navigationListForm")
);

const ListVersionHistory = React.lazy(() =>
  import("views/merchandising/navigationList/listsVersionHistory")
);

const MerchandisingTemplates = React.lazy(() =>
  import("views/merchandising/templates")
);

const MerchandisingBlocks = React.lazy(() =>
  import("views/merchandising/blocks")
);

const MerchandisingBlockVersionHistory = React.lazy(() =>
  import("views/merchandising/blocks/blockVersionHistory")
);


const MerchandisingTemplatesVersionHistory = React.lazy(() =>
  import("views/merchandising/templates/templateVersionHistory")
);

const MerchandisingTemplateForm = React.lazy(() =>
  import("views/merchandising/templates/templateForm")
);

const MerchandisingBlockForm = React.lazy(() =>
  import("views/merchandising/blocks/blockForm")
);

const MerchandisingSections = React.lazy(() =>
  import("views/merchandising/sections")
);
const MerchandisingSectionVersionHistory = React.lazy(() =>
  import("views/merchandising/sections/sectionVersionHistory")
);
const MerchandisingSectionForm = React.lazy(() =>
  import("views/merchandising/sections/sectionForm")
);

const MerchandisingSiteComponents = React.lazy(() =>
  import("views/merchandising/siteComponents")
);

const MerchandisingSiteComponentVersionHistory = React.lazy(() =>
  import("views/merchandising/siteComponents/siteComponentVersionHistory")
);

const MerchandisingSiteComponentForm = React.lazy(() =>
  import("views/merchandising/siteComponents/siteComponentForm")
);

const MerchandisingInjections = React.lazy(() =>
  import("views/merchandising/injection")
);

const MerchandisingInjectionVersionHistory = React.lazy(() =>
  import("views/merchandising/injection/injectionVersionHistory")
);

const MerchandisingInjectionForm = React.lazy(() =>
  import("views/merchandising/injection/injectionForm")
);

const MerchandisingFeatuesOptionForm = React.lazy(() =>
  import("views/merchandising/features/featuresOption/featuresOptionForm")
);

const MerchandisingFeatuesOption = React.lazy(() =>
  import("views/merchandising/features/featuresOption")
);

const MerchandisingFeatuesOptionVersionHistory = React.lazy(() =>
  import("views/merchandising/features/featuresOption/featuresOptionVersionHistory")
);

const MerchandisingFeatuesGroupForm = React.lazy(() =>
  import("views/merchandising/features/featuresGroup/featuresGroupForm")
);

const MerchandisingFeaturesGroup = React.lazy(() =>
  import("views/merchandising/features/featuresGroup")
);

const MerchandisingFeatuesGroupVersionHistory = React.lazy(() =>
  import("views/merchandising/features/featuresGroup/featuresGroupVersionHistory")
);

const MerchandisingFeatuesForm = React.lazy(() =>
  import("views/merchandising/features/featuresForm")
);

const MerchandisingFeatures = React.lazy(() =>
  import("views/merchandising/features")
);

const MerchandisingFeatuesVersionHistory = React.lazy(() =>
  import("views/merchandising/features/featuresVersionHistory")
);
const CollectionsVersionHistory = React.lazy(() => import("views/pim/collections/collections/collectionsVersionHistory"));
const CollectionsTypeVersionHistory = React.lazy(() => import("views/pim/collections/collections-types/collectionsTypeVersionHistory"));

// Activity
const Activity = React.lazy(() => import("views/pim/activity/activity"));
const ActivityForm = React.lazy(() => import("views/pim/activity/activityForm"));
const ActivityVersionHistory = React.lazy(() => import("views/pim/activity/activityVersionHistory"));

// Activity Master
const ActivityMaster = React.lazy(() => import("views/pim/activityMaster/activityMaster"));
const ActivityMasterForm = React.lazy(() => import("views/pim/activityMaster/activityMasterForm"));
const ActivityMasterVersionHistory = React.lazy(() => import("views/pim/activityMaster/activityMasterVersionHistory"));

// Activity CollectionType
const ActivityCollectionTypeMapping = React.lazy(() => import("views/pim/activityCollectionTypeMapping/activityCollectionTypeMapping"));
const ActivityCollectionTypeMappingForm = React.lazy(() => import("views/pim/activityCollectionTypeMapping/activityCollectionTypeMappingForm"));
const ActivityCollectionTypeMappingVersionHistory = React.lazy(() => import("views/pim/activityCollectionTypeMapping/activityCollectionTypeMappingVersionHistory"));

// Activity CollectionType
const ActivityComponentTypeMapping = React.lazy(() => import("views/pim/activityComponentTypeMapping/activityComponentTypeMapping"));
const ActivityComponentTypeMappingForm = React.lazy(() => import("views/pim/activityComponentTypeMapping/activityComponentTypeMappingForm"));
const ActivityComponentTypeMappingVersionHistory = React.lazy(() => import("views/pim/activityComponentTypeMapping/activityComponentTypeMappingVersionHistory"));


// Activity Location
const ActivityLocationMapping = React.lazy(() => import("views/pim/activityLocationMapping/activityLocationMapping"));
const ActivityLocationMappingForm = React.lazy(() => import("views/pim/activityLocationMapping/activityLocationMappingForm"));
const ActivityLocationMappingVersionHistory = React.lazy(() => import("views/pim/activityLocationMapping/activityLocationMappingVersionHistory"));

// Activity Calendar
const ActivityCalendar = React.lazy(() => import("views/pim/activityCalendar/activityCalendar"));
const ActivityCalendarForm = React.lazy(() => import("views/pim/activityCalendar/activityCalendarForm"));
const ActivityCalendarVersionHistory = React.lazy(() => import("views/pim/activityCalendar/activityCalendarVersionHistory"));

// Calendar
const Calendar = React.lazy(() => import("views/pim/calendar/calendar"));
const CalendarForm = React.lazy(() => import("views/pim/calendar/calendarForm"));
const CalendarVersionHistory = React.lazy(() => import("views/pim/calendar/calendarVersionHistory"));


// CAD
// Mould
const Mould = React.lazy(() => import("views/pim/cad/mould/mould"));
const MouldForm = React.lazy(() => import("views/pim/cad/mould/mouldForm"));
const MouldVersionHistory = React.lazy(() => import("views/pim/cad/mould/mouldVersionHistory"));

const Cad = React.lazy(() => import("views/pim/cad/cad/cad"));
const CadForm = React.lazy(() => import("views/pim/cad/cad/cadForm"));
const CadVersionHistory = React.lazy(() => import("views/pim/cad/cad/cadVersionHistory"));

// Media
const Media = React.lazy(() => import("views/pim/media/media"));
const MediaForm = React.lazy(() => import("views/pim/media/mediaForm"));

// Settings
// proeprty type
const PropertyType = React.lazy(() => import("views/settings/propertyType/propertyType"));
const PropertyTypeForm = React.lazy(() => import("views/settings/propertyType/propertyTypeForm"));
const PropertyTypeVersionHistory = React.lazy(() => import("views/settings/propertyType/propertyTypeVersionHistory"));

// relation type
const RelationType = React.lazy(() => import("views/settings/relationType/relationType"));
const RelationTypeForm = React.lazy(() => import("views/settings/relationType/relationTypeForm"));
const RelationTypeVersionHistory = React.lazy(() => import("views/settings/relationType/relationTypeVersionHistory"));

//Tag
const Tags = React.lazy(() => import("views/settings/tags/tags"));
const TagsForm = React.lazy(() => import("views/settings/tags/tagsForm"));
const TagsVersionHistory = React.lazy(() => import("views/settings/tags/tagsVersionHistory"));

// Variants
const variantsOption = React.lazy(() => import("views/pim/variants/variantsOption"));
const VariantSets = React.lazy(() => import("views/pim/variants/variantSets"));

//customers
const Customers = React.lazy(() => import("views/customers/customers/customers"));
const CustomersForm = React.lazy(() => import("views/customers/customers/customersForm"));
const CustomersVersionHistory = React.lazy(() => import("views/customers/customers/customersVersionHistory"));

//Customer Address
const CustomersAddresses = React.lazy(() => import("views/customersAddresses/customersAddresses"));
const CustomersAddressesForm = React.lazy(() => import("views/customersAddresses/customersAddressesForm"));
const CustomersAddressesVersionHistory = React.lazy(() => import("views/customersAddresses/customersAddressesVersionHistory"));


//Country
const Country = React.lazy(() => import("views/country/country"));
const CountryForm = React.lazy(() => import("views/country/countryForm"));
const CountryVersionHistory = React.lazy(() => import("views/country/countryVersionHistory"));

//Location
const Location = React.lazy(() => import("views/location/location"));
const LocationForm = React.lazy(() => import("views/location/locationForm"));
const LocationVersionHistory = React.lazy(() => import("views/location/locationVersionHistory"));
const LocationFormBulkUpdates = React.lazy(() => import("views/location/bulkUpdates/bulkUpdatesLocationForm"));

//Store
const Store = React.lazy(() => import("views/store/store"));
const StoreForm = React.lazy(() => import("views/store/storeForm"));
const StoreVersionHistory = React.lazy(() => import("views/store/storeVersionHistory"));
const StoreFormBulkUpdates = React.lazy(() => import("views/store/bulkUpdates/bulkUpdatesStoreForm"));

//Brand
const Brand = React.lazy(() => import("views/brand/brand"));
const BrandForm = React.lazy(() => import("views/brand/brandForm"));
const BrandVersionHistory = React.lazy(() => import("views/brand/brandVersionHistory"));
const BrandFormBulkUpdates = React.lazy(() => import("views/brand/bulkUpdates/bulkUpdatesBrandForm"));

//Company
const Company = React.lazy(() => import("views/company/company"));
const CompanyForm = React.lazy(() => import("views/company/companyForm"));
const CompanyVersionHistory = React.lazy(() => import("views/company/companyVersionHistory"));
const CompanyFormBulkUpdates = React.lazy(() => import("views/company/bulkUpdates/bulkUpdatesCompanyForm"));

//Coupons
const Coupons = React.lazy(() => import("views/coupon/coupons/coupons"));
const CouponsForm = React.lazy(() => import("views/coupon/coupons/couponsForm"));
const CouponsVersionHistory = React.lazy(() => import("views/coupon/coupons/couponsVersionHistory"));

//CouponsType
const CouponsType = React.lazy(() => import("views/coupon/coupons-type/couponsType"));
const CouponsTypeForm = React.lazy(() => import("views/coupon/coupons-type/couponsTypeForm"));
const CouponsTypeVersionHistory = React.lazy(() => import("views/coupon/coupons-type/couponsTypeVersionHistory"));

//DiscountType
const DiscountType = React.lazy(() => import("views/coupon/discount-type/discountType"));
const DiscountTypeForm = React.lazy(() => import("views/coupon/discount-type/discountTypeForm"));
const DiscountTypeVersionHistory = React.lazy(() => import("views/coupon/discount-type/discountTypeVersionHistory"));

//PaymentMethod
const PaymentMethod = React.lazy(() => import("views/paymentmethod/paymentmethod"));
const PaymentMethodForm = React.lazy(() => import("views/paymentmethod/paymentmethodForm"));
const PaymentMethodVersionHistory = React.lazy(() => import("views/paymentmethod/paymentmethodVersionHistory"));

//ShippingMethod
const ShippingMethod = React.lazy(() => import("views/shippingmethod/shippingmethod"));
const ShippingMethodForm = React.lazy(() => import("views/shippingmethod/shippingmethodForm"));
const ShippingMethodVersionHistory = React.lazy(() => import("views/shippingmethod/shippingmethodVersionHistory"));

//InStorePickup
const InStorePickup = React.lazy(() => import("views/inStorePickup/inStorePickup"));
const InStorePickupForm = React.lazy(() => import("views/inStorePickup/inStorePickupForm"));
const InStorePickupVersionHistory = React.lazy(() => import("views/inStorePickup/inStorePickupVersionHistory"));

//Promotions
const Promotions = React.lazy(() => import("views/promotion/promotions/promotions"));
const PromotionsForm = React.lazy(() => import("views/promotion/promotions/promotionsForm"));
const PromotionsVersionHistory = React.lazy(() => import("views/promotion/promotions/promotionsVersionHistory"));

//Offers
const Offers = React.lazy(() => import("views/promotion/offers/offers"));
const OffersForm = React.lazy(() => import("views/promotion/offers/offersForm"));
const OffersVersionHistory = React.lazy(() => import("views/promotion/offers/offersVersionHistory"));

//Conditions
const Conditions = React.lazy(() => import("views/promotion/conditions/conditions"));
const ConditionsForm = React.lazy(() => import("views/promotion/conditions/conditionsForm"));
const ConditionsVersionHistory = React.lazy(() => import("views/promotion/conditions/conditionsVersionHistory"));

//PromotionsType
const PromotionsType = React.lazy(() => import("views/promotion/promotions-type/promotionsType"));
const PromotionsTypeForm = React.lazy(() => import("views/promotion/promotions-type/promotionsTypeForm"));
const PromotionsTypeVersionHistory = React.lazy(() => import("views/promotion/promotions-type/promotionsTypeVersionHistory"));

//ConditionPropertyOperators
const ConditionPropertyOperators = React.lazy(() => import("views/promotion/condition-property-operators/conditionPropertyOperators"));
const ConditionPropertyOperatorsForm = React.lazy(() => import("views/promotion/condition-property-operators/conditionPropertyOperatorsForm"));
const ConditionPropertyOperatorsVersionHistory = React.lazy(() => import("views/promotion/condition-property-operators/conditionPropertyOperatorsVersionHistory"));

//ConditionProperties
const ConditionProperties = React.lazy(() => import("views/promotion/condition-properties/conditionProperties"));
const ConditionPropertiesForm = React.lazy(() => import("views/promotion/condition-properties/conditionPropertiesForm"));
const ConditionPropertiesVersionHistory = React.lazy(() => import("views/promotion/condition-properties/conditionPropertiesVersionHistory"));

//OfferProperties
const OfferProperties = React.lazy(() => import("views/promotion/offer-properties/offerProperties"));
const OfferPropertiesForm = React.lazy(() => import("views/promotion/offer-properties/offerPropertiesForm"));
const OfferPropertiesVersionHistory = React.lazy(() => import("views/promotion/offer-properties/offerPropertiesVersionHistory"));

//ConditionProperties
const ConditionOfferAttributes = React.lazy(() => import("views/promotion/condition-offer-attributes/conditionOfferAttributes"));
const ConditionOfferAttributesForm = React.lazy(() => import("views/promotion/condition-offer-attributes/conditionOfferAttributesForm"));
const ConditionOfferAttributesVersionHistory = React.lazy(() => import("views/promotion/condition-offer-attributes/conditionOfferAttributesVersionHistory"));

//Currency
const Currency = React.lazy(() => import("views/currency/currency"));
const CurrencyForm = React.lazy(() => import("views/currency/currencyForm"));
const CurrencyVersionHistory = React.lazy(() => import("views/currency/currencyVersionHistory"));

//Pricing
const MetalCost = React.lazy(() => import("views/pricing/metal/metalCost/metalCost"));
const MetalCostForm = React.lazy(() => import("views/pricing/metal/metalCost/metalCostForm"));
const MetalMarkUp = React.lazy(() => import("views/pricing/metal/metalMarkup/metalMarkup"));
const MetalMarkupForm = React.lazy(() => import("views/pricing/metal/metalMarkup/metalMarkupForm"));
const StoneMarkUp = React.lazy(() => import("views/pricing/stone/stoneMarkup/stoneMarkup"));
const StoneMarkupForm = React.lazy(() => import("views/pricing/stone/stoneMarkup/stoneMarkupForm"));
const StoneVendorMarkUp = React.lazy(() => import("views/pricing/stone/stoneVendorMarkup/stoneVendorMarkup"));
const StoneCollectionMarkUp = React.lazy(() => import("views/pricing/stone/stoneCollectionMarkup/stoneCollectionMarkup"));
const StoneStoreMarkUp = React.lazy(() => import("views/pricing/stone/stoneStoreMarkup/stoneStoreMarkup"));
const StoneTagMarkUp = React.lazy(() => import("views/pricing/stone/stoneTagMarkup/stoneTagMarkup"));
const StoreCollectionMarkUp = React.lazy(() => import("views/pricing/store/storeCollectionMarkup/storeCollectionMarkup"));
const StoreCollectionMarkupForm = React.lazy(() => import("views/pricing/store/storeCollectionMarkup/storeCollectionMarkupForm"));
const StoreCollectionTypeMarkUp = React.lazy(() => import("views/pricing/store/storeCollectionTypeMarkup/storeCollectionTypeMarkup"));
const StoreCollectionTypeMarkupForm = React.lazy(() => import("views/pricing/store/storeCollectionTypeMarkup/storeCollectionTypeMarkupForm"));
const ProductPricingCalculatorForm = React.lazy(() => import("views/pricing/productPricingCalculator/productPricingCalculatorForm"));
const SavedPricing = React.lazy(() => import("views/pricing/productPricingCalculator/SavedPricing"));
const PriceCalculationResults = React.lazy(() => import("views/pricing/productPricingCalculator/priceCalculationResults"));
const MetalCostVersionHistory = React.lazy(() => import("views/pricing/metal/metalCost/metalCostVersionHistory"));
const MetalMarkupVersionHistory = React.lazy(() => import("views/pricing/metal/metalMarkup/metalMarkupVersionHistory"));
const StoneMarkupVersionHistory = React.lazy(() => import("views/pricing/stone/stoneMarkup/stoneMarkupVersionHistory"));
const StoreCollectionMarkupVersionHistory = React.lazy(() => import("views/pricing/store/storeCollectionMarkup/storeCollectionMarkupVersionHistory"));
const StoreCollectionTypeMarkupVersionHistory =React.lazy(() => import("views/pricing/store/storeCollectionTypeMarkup/storeCollectionTypeMarkupVersionHistory"));
const Markups = React.lazy(() => import("views/pricing/markup/markups"));
const FindingCost = React.lazy(() => import("views/pricing/findingCost/findingCost"));
const FindingCostForm = React.lazy(() => import("views/pricing/findingCost/findingCostForm"));
const FindingCostVersionHistory = React.lazy(() => import("views/pricing/findingCost/findingCostVersionHistory"));
const ICTMarkup = React.lazy(() => import("views/pricing/ictMarkup/ictMarkup"));
const ICTMarkupVersionHistory = React.lazy(() => import("views/pricing/ictMarkup/ictMarkupVersionHistory"));
const FindingMarkup = React.lazy(() => import("views/pricing/findingMarkup/findingMarkup"));
const FindingMarkupVersionHistory = React.lazy(() => import("views/pricing/findingMarkup/findingMarkupVersionHistory"));
const ThirdPartyProductMarkup = React.lazy(() => import("views/pricing/thirdPartyProductMarkup/thirdPartyProductMarkup"));
const ThirdPartyProductMarkupVersionHistory = React.lazy(() => import("views/pricing/thirdPartyProductMarkup/thirdPartyProductMarkupVersionHistory"));

// Incentives

const MetalIncentives = React.lazy(() => import("views/Incentives/metal/metalIncentives"))
const MetalIncentivesForm = React.lazy(() => import("views/Incentives/metal/metalIncentivesForm"))
const MetalIncentivesVersionHistory = React.lazy(() => import("views/Incentives/metal/metalIncentivesVersionHistory"))
const StoneIncentives = React.lazy(() => import("views/Incentives/stone/stoneIncentives"))
const StoneIncentivesForm = React.lazy(() => import("views/Incentives/stone/stoneIncentivesForm"))
const StoneIncentivesVersionHistory = React.lazy(() => import("views/Incentives/stone/stoneIncentivesVersionHistory"))
const CollectionIncentives = React.lazy(() => import("views/Incentives/collection/collectionIncentives"))
const CollectionIncentivesForm = React.lazy(() => import("views/Incentives/collection/collectionIncentivesForm"))
const CollectionIncentivesVersionHistory = React.lazy(() => import("views/Incentives/collection/collectionIncentivesVersionHistory"))
const InstockJewelleryProductIncentives = React.lazy(() => import("views/Incentives/InstockJewelleryProduct/instockJewelleryProductIncentives"))
const InstockJewelleryProductIncentivesForm = React.lazy(() => import("views/Incentives/InstockJewelleryProduct/instockJewelleryProductIncentivesForm"))
const InstockJewelleryProductIncentivesVersionHistory = React.lazy(() => import("views/Incentives/InstockJewelleryProduct/instockJewelleryProductIncentivesVersionHistory"))
const AddonIncentives = React.lazy(() => import("views/Incentives/addon/addonIncentives"))
const AddonIncentivesForm = React.lazy(() => import("views/Incentives/addon/addonIncentivesForm"))
const AddonIncentivesVersionHistory = React.lazy(() => import("views/Incentives/addon/addonIncentivesVersionHistory"))
const ServiceIncentives = React.lazy(() => import("views/Incentives/service/serviceIncentives"))
const ServiceIncentivesForm = React.lazy(() => import("views/Incentives/service/serviceIncentivesForm"))
const ServiceIncentivesVersionHistory = React.lazy(() => import("views/Incentives/service/serviceIncentivesVersionHistory"))
const IncentiveCalculatorForm = React.lazy(() => import("views/orders/order-incentive-calculator/incentiveCalculatorForm"))
const OderLineItemIncentive = React.lazy(() => import("views/orders/order-line-item-incentive/oderLineItemIncentive"))
const AddonFormBulkUpdates = React.lazy(() => import("views/pim/add-on/add-on/bulkUpdates/bulkUpdatesAddonForm"));


//Transfers
const Transfers = React.lazy(() => import("views/transfers/transfers/transfers"))
const TransfersForm = React.lazy(() => import("views/transfers/transfers/transfersForm"))
const TransfersVersionHistory = React.lazy(() => import("views/transfers/transfers/transfersVersionHistory"))
const TransferInternalStates = React.lazy(() => import("views/transfers/transferInternalState/transferInternalState"))
const TransferInternalStatesForm = React.lazy(() => import("views/transfers/transferInternalState/transferInternalStateForm"))
const TransferInternalStatesVersionHistory = React.lazy(() => import("views/transfers/transferInternalState/transferInternalStateVersionHistory"))
const StockTagInternalStates = React.lazy(() => import("views/transfers/stockTagInternalState/stockTagInternalState"))
const StockTagInternalStatesForm = React.lazy(() => import("views/transfers/stockTagInternalState/stockTagInternalStateForm"))
const StockTagInternalStatesVersionHistory = React.lazy(() => import("views/transfers/stockTagInternalState/stockTagInternalStateVersionHistory"))

//Inventory Tag Internal States
const InventoryTagInternalStates = React.lazy(() => import("views/transfers/inventoryTagInternalState/inventoryTagInternalState"))
const InventoryTagInternalStatesForm = React.lazy(() => import("views/transfers/inventoryTagInternalState/inventoryTagInternalStateForm"))
const InventoryTagInternalStatesVersionHistory = React.lazy(() => import("views/transfers/inventoryTagInternalState/inventoryTagInternalStateVersionHistory"))

//Payment Internal States
const PaymentInternalStates = React.lazy(() => import("views/transfers/paymentInternalState/paymentInternalState"))
const PaymentInternalStatesForm = React.lazy(() => import("views/transfers/paymentInternalState/paymentInternalStateForm"))
const PaymentInternalStatesVersionHistory = React.lazy(() => import("views/transfers/paymentInternalState/paymentInternalStateVersionHistory"))

// jwellery product
const JewelleryProduct = React.lazy(() => import("views/transfers/jwelleryProduct/jewelleryProduct"))
const JewelleryProductForm = React.lazy(() => import("views/transfers/jwelleryProduct/jewelleryProductForm"))
const JewelleryProductVersionHistory = React.lazy(() => import("views/transfers/jwelleryProduct/jewelleryProductVersionHistory"))
const IncentiveCalculationResults = React.lazy(() => import("views/orders/order-incentive-calculator/incentiveCalculationResults"))


const ASMIncentive = React.lazy(() => import("views/asmIncentive/asmIncentive"))
const ASMIncentiveForm = React.lazy(() => import("views/asmIncentive/asmIncentiveForm"))
const ASMIncentiveVersionHistory = React.lazy(() => import("views/asmIncentive/asmIncentiveVersionHistory"))


const PoState = React.lazy(() => import("views/procurement/states/po-state/poState"))
const PoStateForm = React.lazy(() => import("views/procurement/states/po-state/poStateForm"))
const PoLineItemState = React.lazy(() => import("views/procurement/states/po-line-item-state/poLineItemState"))
const PoLineItemStateForm = React.lazy(() => import("views/procurement/states/po-line-item-state/poLineItemStateForm"))

const GrnState = React.lazy(() => import("views/procurement/states/grn-state/grnState"))
const GrnStateForm = React.lazy(() => import("views/procurement/states/grn-state/grnStateForm"))

const GrnLineItemState = React.lazy(() => import("views/procurement/states/grn-line-item-state/grnLineItemState"))
const GrnLineItemStateForm = React.lazy(() => import("views/procurement/states/grn-line-item-state/grnLineItemStateForm"))

const OrderLineItemDemandState = React.lazy(() => import("views/procurement/states/orderLineItemDemandStates/orderLineItemDemandState"))
const OrderLineItemDemandStateForm = React.lazy(() => import("views/procurement/states/orderLineItemDemandStates/orderLineItemDemandStateForm"))

const OrderLineItemDemandStoneState = React.lazy(() => import("views/procurement/states/orderLineItemDemandStoneStates/orderLineItemDemandStoneState"))
const OrderLineItemDemandStoneStateForm = React.lazy(() => import("views/procurement/states/orderLineItemDemandStoneStates/orderLineItemDemandStoneStateForm"))

const PrState = React.lazy(() => import("views/procurement/states/pr-state/prState"))
const PrStateForm = React.lazy(() => import("views/procurement/states/pr-state/prStateForm"))

const ServiceChargePo = React.lazy(() => import("views/procurement/serviceChargePo/serviceChargePo"));
const ServiceChargePoForm = React.lazy(() => import("views/procurement/serviceChargePo/serviceChargePoForm"));
const ServiceChargePoVersionHistory = React.lazy(() => import("views/procurement/serviceChargePo/serviceChargePoVersionHistory"));

const GrnQcState = React.lazy(() => import("views/procurement/grnQcStates/grnQcStates"))
const GrnQcStateForm = React.lazy(() => import("views/procurement/grnQcStates/grnQcStatesForm"))
const GrnQcStateVersionHistory = React.lazy(() => import("views/procurement/grnQcStates/grnQcStatesVersionHistory"))

// Appointment
const Appointment = React.lazy(() => import("views/appointment/appointment/appointment"))
const AppointmentForm = React.lazy(() => import("views/appointment/appointment/appointmentForm"))
const AppointmentVersionHistory = React.lazy(() => import("views/appointment/appointment/appointmentVersionHistory"))

// Appointment Internal States
const AppointmentInternalStates = React.lazy(() => import("views/appointment/appointmentInternalState/appointmentInternalState"))
const AppointmentInternalStatesForm = React.lazy(() => import("views/appointment/appointmentInternalState/appointmentInternalStateForm"))
const AppointmentInternalStatesVersionHistory = React.lazy(() => import("views/appointment/appointmentInternalState/appointmentInternalStateVersionHistory"))

// Appointment Type
const AppointmentTypes = React.lazy(() => import("views/appointment/appointmentType/appointmentType"))
const AppointmentTypesForm = React.lazy(() => import("views/appointment/appointmentType/appointmentTypeForm"))
const AppointmentTypesVersionHistory = React.lazy(() => import("views/appointment/appointmentType/appointmentTypeVersionHistory"))

// Appointment Type
const AppointmentModes = React.lazy(() => import("views/appointment/appointmentMode/appointmentMode"))
const AppointmentModesForm = React.lazy(() => import("views/appointment/appointmentMode/appointmentModeForm"))
const AppointmentModesVersionHistory = React.lazy(() => import("views/appointment/appointmentMode/appointmentModeVersionHistory"))

const CustomersEnquiries = React.lazy(() => import("views/customers/customersEnquiries/customersEnquiries"));
const CustomersEnquiriesForm = React.lazy(() => import("views/customers/customersEnquiries/customersEnquiriesForm"));
const CustomersEnquiriesVersionHistory = React.lazy(() => import("views/customers/customersEnquiries/customersEnquiriesVersionHistory"));

const CustomersEnquiriyRequests = React.lazy(() => import("views/customers/customersEnquiriyRequests/customersEnquiriyRequests"));
const CustomersEnquiriyRequestsForm = React.lazy(() => import("views/customers/customersEnquiriyRequests/customersEnquiriyRequestsForm"));
const CustomersEnquiriyRequestsVersionHistory = React.lazy(() => import("views/customers/customersEnquiriyRequests/customersEnquiriyRequestsVersionHistory"));

const ActivityCollectionMapping = React.lazy(() => import("views/pim/activityCollectionMapping/activityCollectionMapping"));
const ActivityCollectionMappingForm = React.lazy(() => import("views/pim/activityCollectionMapping/activityCollectionMappingForm"));
const ActivityCollectionMappingEditForm = React.lazy(() => import("views/pim/activityCollectionMapping/activityCollectionMappingEditForm"));

const ActivityComponentMapping = React.lazy(() => import("views/pim/activityComponentMapping/activityComponentMapping"));
const ActivityComponentMappingForm = React.lazy(() => import("views/pim/activityComponentMapping/activityComponentMappingForm"));
const ActivityComponentMappingEditForm = React.lazy(() => import("views/pim/activityComponentMapping/activityComponentMappingEditForm"));

const CollectionsProperties = React.lazy(() => import("views/pim/collections/collections-properties/collectionsProperties"));
const CollectionsPropertiesForm = React.lazy(() => import("views/pim/collections/collections-properties/collectionsPropertiesForm"));
const CollectionsPropertiesVersionHistory = React.lazy(() => import("views/pim/collections/collections-properties/collectionsPropertiesVersionHistory"));

const CollectionsUnits = React.lazy(() => import("views/pim/collections/collections-units/collectionsUnits"));
const CollectionsUnitsForm = React.lazy(() => import("views/pim/collections/collections-units/collectionsUnitsForm"));
const CollectionsUnitsVersionHistory = React.lazy(() => import("views/pim/collections/collections-units/collectionsUnitsVersionHistory"));

const RepairRequest = React.lazy(() => import("views/orders/repairRequest/repairRequest"))
const RepairRequestForm = React.lazy(() => import("views/orders/repairRequest/repairRequestForm"))
const RepairRequestVersionHistory = React.lazy(() => import("views/orders/repairRequest/repairRequestVersionHistory"))
const RepairRequestState = React.lazy(() => import("views/orders/repairRequestState/repairRequestState"))
const RepairRequestStateForm = React.lazy(() => import("views/orders/repairRequestState/repairRequestStateForm"))

const ReplacementRequest = React.lazy(() => import("views/orders/replacementRequest/replacementRequest"))
const ReplacementRequestForm = React.lazy(() => import("views/orders/replacementRequest/replacementRequestForm"))
const ReplacementRequestVersionHistory = React.lazy(() => import("views/orders/replacementRequest/replacementRequestVersionHistory"))
const ReplacementRequestState = React.lazy(() => import("views/orders/replacementRequestState/replacementRequestState"))
const ReplacementRequestStateForm = React.lazy(() => import("views/orders/replacementRequestState/replacementRequestStateForm"))

const BankDeposite = React.lazy(() => import("views/Finance/bankDeposite/bankDeposite"));
const BankDepositeForm = React.lazy(() => import("views/Finance/bankDeposite/bankDepositeForm"));

const CashLedger = React.lazy(() => import("views/Finance/cashLedger/cashLedger"));
const CurrencyRates = React.lazy(() => import("views/Finance/currencyRates/currencyRates"));
const CurrencyRatesForm = React.lazy(() => import("views/Finance/currencyRates/currencyRatesForm"));
const CurrencyHedge = React.lazy(() => import("views/Finance/currencyHedge/currencyHedge"));
const CashOrdersListForm = React.lazy(() => import("views/orders/cashOrdersListForm"));

const TaxSetup = React.lazy(() => import("views/settings/taxSetup/taxSetup"));
const TaxSetupForm = React.lazy(() => import("views/settings/taxSetup/taxSetupForm"));
const LogisticsProvider = React.lazy(() => import("views/settings/logisticsProvider/logisticsProvider"));
const LogisticsProviderForm = React.lazy(() => import("views/settings/logisticsProvider/logisticsProviderForm"));
const LogisticsProviderVersionHistory = React.lazy(() => import("views/settings/logisticsProvider/logisticsProviderVersionHistory"));

// vendor
const Vendor = React.lazy(() => import("views/settings/vendor/vendor"));
const VendorForm = React.lazy(() => import("views/settings/vendor/vendorForm"));
const VendorVersionHistory = React.lazy(() => import("views/settings/vendor/vendorVersionHistory"));
const VendorFormBulkUpdates = React.lazy(() => import("views/settings/vendor/bulkUpdates/bulkUpdatesVendorForm"));

// shipment Tracking
const ShipmentTracking = React.lazy(() => import("views/settings/shipmentTracking/shipmentTracking"));
const ShipmentTrackingForm = React.lazy(() => import("views/settings/shipmentTracking/shipmentTrackingForm"));
const ShipmentTrackingVersionHistory = React.lazy(() => import("views/settings/vendor/vendorVersionHistory"));

// Shipment Method
const ShipmentMethods = React.lazy(() => import("views/settings/shipmentMethods/shipmentMethods"));
const ShipmentMethodsForm = React.lazy(() => import("views/settings/shipmentMethods/shipmentMethodsForm"));
const ShipmentMethodsVersionHistory = React.lazy(() => import("views/settings/shipmentMethods/shipmentMethodsVersionHistory"));

// LocationLogisticsProviderMaps
const LocationLogisticsProviderMaps = React.lazy(() => import("views/settings/locationLogisticsProviderMaps/locationLogisticsProviderMaps"));
const LocationLogisticsProviderMapsForm = React.lazy(() => import("views/settings/locationLogisticsProviderMaps/locationLogisticsProviderMapsForm"));


//Vendor Feed
const Vendors = React.lazy(()=>import("views/vendorFeed/vendors"));
const AddVendors=React.lazy(()=>import("views/vendorFeed/addVendor"));
const VendorConfig=React.lazy(()=>import("views/vendorFeed/VendorConfig"));
const AddVendorConfig=React.lazy(()=>import("views/vendorFeed/AddVendorConfig"));

const FilterStoneData=React.lazy(()=>import("views/vendorFeed/FilterStoneData"));

//Cron Job
const CronJob = React.lazy(()=>import("views/cronJob/cron"));
const AddCron = React.lazy(()=>import("views/cronJob/addCron"));
const EditCron = React.lazy(()=>import("views/cronJob/addCron"));

//Scheduled And Locking
const Scheduled = React.lazy(() => import("views/Scheduled"));

//Import Duties
const ImportDuties = React.lazy(() => import("views/settings/importDuties/importDuties"));
const ImportDutiesForm = React.lazy(() => import("views/settings/importDuties/importDutiesForm"));
const ImportDutiesVersionHistory = React.lazy(() => import("views/settings/importDuties/importDutiesVersionHistory"));

//Goods Category
const GoodsCategory = React.lazy(() => import("views/settings/goodsCategory/goodsCategory"));
const GoodsCategoryForm = React.lazy(() => import("views/settings/goodsCategory/goodsCategoryForm"));
const GoodsCategoryVersionHistory = React.lazy(() => import("views/settings/goodsCategory/goodsCategoryVersionHistory"));

// address
const Address = React.lazy(() => import("views/address/address"));
const AddressForm = React.lazy(() => import("views/address/addressForm"));
const AddressVersionHistory = React.lazy(() => import("views/address/addressVersionHistory"));
const AddressFormBulkUpdates = React.lazy(() => import("views/address/bulkUpdates/bulkUpdatesAddressForm"));

const BespokeEnquiries = React.lazy(() => import("views/customers/bespokeEnquiries/bespokeEnquiries"));
const BespokeEnquiriesForm = React.lazy(() => import("views/customers/bespokeEnquiries/bespokeEnquiriesForm"));
const BespokeEnquiriesVersionHistory = React.lazy(() => import("views/customers/bespokeEnquiries/bespokeEnquiriesVersionHistory"));
// QC IN  
const QCIn = React.lazy(() => import("views/procurement/qc/qcIn/qcIn"));
const QCInForm = React.lazy(() => import("views/procurement/qc/qcIn/qcInForm"));
const QCInVersionHistory = React.lazy(() => import("views/procurement/qc/qcIn/qcInVersionHistory"));

// QC Approved  
const QCApproved = React.lazy(() => import("views/procurement/qc/qcApproved/qcApproved"));
const QCApprovedForm = React.lazy(() => import("views/procurement/qc/qcApproved/qcApprovedForm"));
const QCApprovedVersionHistory = React.lazy(() => import("views/procurement/qc/qcApproved/qcApprovedVersionHistory"));

// QC Reject  
const QCReject = React.lazy(() => import("views/procurement/qc/qcReject/qcReject"));
const QCRejectForm = React.lazy(() => import("views/procurement/qc/qcReject/qcRejectForm"));
const QCRejectVersionHistory = React.lazy(() => import("views/procurement/qc/qcReject/qcRejectVersionHistory"));


const BespokeQuestionnaires = React.lazy(() => import("views/customers/bespokeQuestionnaires/bespokeQuestionnaires"));
const BespokeQuestionnairesForm = React.lazy(() => import("views/customers/bespokeQuestionnaires/bespokeQuestionnairesForm"));
const BespokeQuestionnairesVersionHistory = React.lazy(() => import("views/customers/bespokeQuestionnaires/bespokeQuestionnairesVersionHistory"));

const OrderDemandStoneResults = React.lazy(() => import("views/procurement/orderDemandLineItem/orderDemandStoneResults"));

const Reconcilation = React.lazy(() => import("views/orders/reconciliation/reconciliation/Reconciliation"));
const ReconcilationForm = React.lazy(() => import("views/orders/reconciliation/reconciliation/ReconciliationForm"));
const StockTagReconciliationVersionHistory = React.lazy(() => import("views/orders/reconciliation/reconciliation/StockTagReconciliationVersionHistory"));
const InventoryTagReconciliationVersionHistory = React.lazy(() => import("views/orders/reconciliation/reconciliation/InventoryTagReconciliationVersionHistory"));

// shipment Tracking Internal State 
const ShipmentTrackingInternalState = React.lazy(() => import("views/settings/shipmentTrackingInternalState/shipmentTrackingInternalState"));
const ShipmentTrackingInternalStateForm = React.lazy(() => import("views/settings/shipmentTrackingInternalState/shipmentTrackingInternalStateForm"));

// Exmaple Table
// Variable Mappings
const componentMap = {
  Dashboard,
  Roles: Roles,
  RoleForm: RoleForm,
  UserLocation: UserLocation,
  UserLocationForm: UserLocationForm,
  Users: Users,
  UserForm: UserForm,
  RolePermissions: RolePermissions,
  RolePermissionForm: RolePermissionForm,
  UserRole: UserRole,
  UserRoleForm: UserRoleForm,
  Permission: Permission,
  PermissionForm: PermissionForm,
  Orders: Orders,
  OrderVersion: OrderVersion,
  OrdersForm: OrdersWizard,
  OrdersExternalState: OrdersExternalState,
  OrdersExternalStateForm: OrdersExternalStateForm,
  OrdersInternalState: OrdersInternalState,
  OrdersInternalStateForm: OrdersInternalStateForm,
  OrdersLineItemExternalState: OrdersLineItemExternalState,
  OrdersLineItemExternalStateForm: OrdersLineItemExternalStateForm,
  OrdersLineItemInternalState: OrdersLineItemInternalState,
  OrdersLineItemInternalStateForm: OrdersLineItemInternalStateForm,
  MetalUnits: MetalUnits,
  MetalUnitsForm: MetalUnitsForm,
  MetalTypes: MetalTypes,
  MetalTypesForm: MetalTypesForm,
  MetalsTypesVersionHistory: MetalsTypesVersionHistory,
  MetalProperties: MetalProperties,
  MetalPropertiesForm: MetalPropertiesForm,
  Metals: Metals,
  MetalsVersionHistory: MetalsVersionHistory,
  MetalForm: MetalForm,
  MetalFormBulkUpdates,
  Finish: Finish,
  FinishForm: FinishForm,
  FinishVersionHistory: FinishVersionHistory,
  StoneUnits: StoneUnits,
  StoneUnitsForm: StoneUnitsForm,
  StoneTypes: StoneTypes,
  StoneTypesForm: StoneTypesForm,
  StoneProperty: StoneProperty,
  StonePropertyForm: StonePropertyForm,
  Stones: Stones,
  StoneFormBulkUpdates,
  StoneForm: StoneForm,
  StoneSettings: StoneSettings,
  StoneSettingsForm: StoneSettingsForm,
  StoneSettingsVersionHistory: StoneSettingsVersionHistory,
  Position: Position,
  PositionForm: PositionForm,
  PositionVersionHistory: PositionVersionHistory,
  ServiceUnits: ServiceUnits,
  ServiceUnitsForm: ServiceUnitsForm,
  ServiceTypes: ServiceTypes,
  ServiceTypesForm: ServiceTypesForm,
  ServiceProperties: ServiceProperties,
  ServicePropertiesForm: ServicePropertiesForm,
  Services: Services,
  ServiceForm: ServiceForm,
  ServicesFormBulkUpdates,
  Rules: Rules,
  RuleEditor: RuleEditor,
  RuleVersion: RuleVersion,
  Finding: Finding,
  FindingForm: FindingForm,
  FindingUnits: FindingUnits,
  FindingUnitsForm: FindingUnitsForm,
  FindingTypes: FindingTypes,
  FindingTypesForm: FindingTypesForm,
  FindingProperties: FindingProperties,
  FindingPropertiesForm: FindingPropertiesForm,
  FindingPropertiesVersionHistory,
  FindingVersionHistory,
  FindingTypesVersionHistory,
  FindingUnitsVersionHistory,
  FindingFormBulkUpdates,
  AddonFormBulkUpdates,
  Collections: Collections,
  CollectionsForm: CollectionsForm,
  CollectionsTypes: CollectionsTypes,
  CollectionsTypesForm: CollectionsTypesForm,
  CollectionsVersionHistory: CollectionsVersionHistory,
  CollectionsTypeVersionHistory: CollectionsTypeVersionHistory,
  MetalPropertiesVersionHistory: MetalPropertiesVersionHistory,
  MetalUnitsVersionHistory: MetalUnitsVersionHistory,
  StonesVersionHistory: StonesVersionHistory,
  StoneTypesVersionHistory: StoneTypesVersionHistory,
  StonePropertiesVersionHistory: StonePropertiesVersionHistory,
  StoneUnitsVersionHistory: StoneUnitsVersionHistory,
  ServicesVersionHistory: ServicesVersionHistory,
  ServiceTypesVersionHistory: ServiceTypesVersionHistory,
  ServicePropertiesVersionHistory: ServicePropertiesVersionHistory,
  ServiceUnitsVersionHistory: ServiceUnitsVersionHistory,
  Activity: Activity,
  ActivityForm: ActivityForm,
  ActivityVersionHistory: ActivityVersionHistory,

  ActivityMaster,
  ActivityMasterForm,
  ActivityMasterVersionHistory,

  ActivityCollectionTypeMapping,
  ActivityCollectionTypeMappingForm,
  ActivityCollectionTypeMappingVersionHistory,

  ActivityComponentTypeMapping,
  ActivityComponentTypeMappingForm,
  ActivityComponentTypeMappingVersionHistory,

  Mould: Mould,
  MouldForm: MouldForm,
  MouldVersionHistory: MouldVersionHistory,
  variantsOption,
  VariantSets,

  //Tags
  Tags,
  TagsForm,
  TagsVersionHistory,

  //Family
  Family,
  FamilyForm,
  FamilyTypes,
  FamilyTypesForm,
  FamilyVersionHistory,
  FamilyTypesVersionHistory,

  //Components
  Component,
  ComponentForm,
  ComponentFormBulkUpdates,
  ComponentUnits,
  ComponentUnitsForm,
  ComponentTypes,
  ComponentTypesForm,
  ComponentProperties,
  ComponentPropertiesForm,
  ComponentVersionHistory,
  ComponentPropertiesVersionHistory,
  ComponentTypesVersionHistory,
  ComponentUnitsVersionHistory,
  //Set
  ComponentSets,
  ComponentSetForm,
  ComponentSetUnits,
  ComponentSetUnitsForm,
  ComponentSetProperties,
  ComponentSetPropertiesForm,
  ComponentSetPropertiesVersionHistory,
  ComponentSetVersionHistory,
  ComponentSetUnitsVersionHistory,

  //Add-On
  AddOnUnits,
  AddOnUnitsForm,
  AddOnTypes,
  AddOnTypesForm,
  AddOnProperties,
  AddOnPropertiesForm,
  AddOn,
  AddOnForm,
  AddOnVersionHistory,
  AddOnTypesVersionHistory,
  AddOnPropertiesVersionHistory,
  AddOnUnitsVersionHistory,

  // Merchandising
  Lists,
  ListForm,
  ListVersionHistory,
  NavigationHierarchy,
  NavigationHierarchyForm,
  NavigationHierarchyVersionHistory,
  MerchandisingTemplates,
  MerchandisingBlocks,
  MerchandisingBlockVersionHistory,
  MerchandisingTemplatesVersionHistory,
  MerchandisingTemplateForm,
  MerchandisingBlockForm,
  MerchandisingSections,
  MerchandisingSectionVersionHistory,
  MerchandisingSectionForm,
  MerchandisingSiteComponents,
  MerchandisingSiteComponentForm,
  MerchandisingSiteComponentVersionHistory,
  MerchandisingFeatuesOptionForm,
  MerchandisingFeatuesOption,
  MerchandisingFeatuesOptionVersionHistory,
  MerchandisingFeatuesGroupForm,
  MerchandisingFeaturesGroup,
  MerchandisingFeatuesGroupVersionHistory,
  MerchandisingFeatuesForm,
  MerchandisingFeatures,
  MerchandisingFeatuesVersionHistory,
  MerchandisingInjections,
  MerchandisingInjectionVersionHistory,
  MerchandisingInjectionForm,
  //Cad
  Cad,
  CadForm,
  CadVersionHistory,

  // Media
  Media,
  MediaForm,
  //PropertyType
  PropertyType,
  PropertyTypeForm,
  PropertyTypeVersionHistory,

  //Customers
  Customers,
  CustomersForm,
  CustomersVersionHistory,

  //Customers Addresses
  CustomersAddresses,
  CustomersAddressesForm,
  CustomersAddressesVersionHistory,

  //Country
  Country,
  CountryForm,
  CountryVersionHistory,

  //Location
  Location,
  LocationForm,
  LocationVersionHistory,
  LocationFormBulkUpdates,

  //Store
  Store,
  StoreForm,
  StoreVersionHistory,
  StoreFormBulkUpdates,

  //Brand
  Brand,
  BrandForm,
  BrandVersionHistory,
  BrandFormBulkUpdates,

  //Company
  Company,
  CompanyForm,
  CompanyVersionHistory,
  CompanyFormBulkUpdates,

  ImportDuties,
  ImportDutiesForm,
  ImportDutiesVersionHistory,

  GoodsCategory,
  GoodsCategoryForm,
  GoodsCategoryVersionHistory,  

  //Procurement
  PrLineItem,
  PrLineItemForm,
  Po,
  PoForm,
  PoLineItem,
  PoLineItemForm,
  PoLineItemDetail,
  PoLineItemDetailForm,
  PoLineItemDetailVersionHistory,
  OrderLineItemDemand,
  OrderLineItemDemandForm,
  OrderLineItem,
  OrderLineItemVersionHistory,
  orderLineItemForm,
  StockReconciliationStates,
  StockReconciliationStatesForm,
  InventoryTagReconciliationStates,
  InventoryTagReconciliationStatesForm,
  ReconciliationType,
  ReconciliationTypeForm,
  ReconciliationDetails,
  ReconciliationDetailsForm,
  StockReconcilation,
  StockReconcilationForm,
  InventoryTagReconciliation,
  InventoryTagReconciliationForm,
  FullProductState,
  FullProductStateForm,
  StockProductStates,
  StockProductStatesForm,
  VendorProductState,
  VendorProductStateForm,
  ServiceProductState,
  ServiceProductStateForm,

  MetalState,
  MetalStateForm,

  AddOnState,
  AddOnStateForm,

  FindingState,
  FindingStateForm,

  ComponentState,
  ComponentStateForm,

  //Gift Vouchers
  GiftVoucher,
  GiftVoucherForm,
  GiftVoucherVersionHistory,

  //Pricing
  FindingCost,
  FindingCostForm,
  FindingCostVersionHistory,
  MetalCost,
  MetalMarkUp,
  StoneMarkUp,
  StoneVendorMarkUp,
  StoneCollectionMarkUp,
  StoneStoreMarkUp,
  StoneTagMarkUp,
  StoreCollectionMarkUp,
  StoreCollectionTypeMarkUp,
  MetalCostForm,
  MetalMarkupForm,
  StoreCollectionMarkupForm,
  ICTMarkup,
  ICTMarkupVersionHistory,
  FindingMarkup,
  FindingMarkupVersionHistory,
  ThirdPartyProductMarkup,
  ThirdPartyProductMarkupVersionHistory,
  //Storage Management
  StockProduct,
  StockProductForm,
  StockProductVersionHistory,
  StockTag,
  StockTagForm,
  StockTagVersionHistory,
  StockTray,
  StockTrayForm,
  StockTrayVersionHistory,
  StockTrayFormBulkUpdates,
  InventoryTray,
  InventoryTrayForm,
  InventoryTrayVersionHistory,
  ProductPricingCalculatorForm,
  SavedPricing,
  StoreCollectionTypeMarkupForm,

  //Inventory Tag
  InventoryTag,
  InventoryTagForm,
  InventoryTagVersionHistory,

  //Coupons
  Coupons,
  CouponsForm,
  CouponsVersionHistory,
  CouponsType,
  CouponsTypeForm,
  CouponsTypeVersionHistory,
  DiscountType,
  DiscountTypeForm,
  DiscountTypeVersionHistory,

  //PaymentMethod
  PaymentMethod,
  PaymentMethodForm,
  PaymentMethodVersionHistory,

  //ShippingMethod
  ShippingMethod,
  ShippingMethodForm,
  ShippingMethodVersionHistory,

  //InStorePickup
  InStorePickup,
  InStorePickupForm,
  InStorePickupVersionHistory,

  //Promotions
  Promotions,
  PromotionsForm,
  PromotionsVersionHistory,
  PromotionsType,
  PromotionsTypeForm,
  PromotionsTypeVersionHistory,
  ConditionPropertyOperators,
  ConditionPropertyOperatorsForm,
  ConditionPropertyOperatorsVersionHistory,
  ConditionProperties,
  ConditionPropertiesForm,
  ConditionPropertiesVersionHistory,
  OfferProperties,
  OfferPropertiesForm,
  OfferPropertiesVersionHistory,
  ConditionOfferAttributes,
  ConditionOfferAttributesForm,
  ConditionOfferAttributesVersionHistory,
  Offers,
  OffersForm,
  OffersVersionHistory,
  Conditions,
  ConditionsForm,
  ConditionsVersionHistory,

  //Currency
  Currency,
  CurrencyForm,
  CurrencyVersionHistory,

  //Finance
  PettyCash,
  StoneMarkupForm,
  PriceCalculationResults,

  //Incentives
  MetalIncentives,
  MetalIncentivesForm,
  MetalIncentivesVersionHistory,
  StoneIncentives,
  StoneIncentivesForm,
  StoneIncentivesVersionHistory,
  CollectionIncentives,
  CollectionIncentivesForm,
  CollectionIncentivesVersionHistory,
  InstockJewelleryProductIncentives,
  InstockJewelleryProductIncentivesForm,
  InstockJewelleryProductIncentivesVersionHistory,
  AddonIncentives,
  AddonIncentivesForm,
  AddonIncentivesVersionHistory,
  ServiceIncentives,
  ServiceIncentivesForm,
  ServiceIncentivesVersionHistory,
  MetalCostVersionHistory,
  MetalMarkupVersionHistory,
  StoneMarkupVersionHistory,

  //Transfers
  Transfers,
  TransfersForm,
  TransfersVersionHistory,
  TransferInternalStates,
  TransferInternalStatesForm,
  TransferInternalStatesVersionHistory,
  StockTagInternalStates,
  StockTagInternalStatesForm,
  StockTagInternalStatesVersionHistory,

  //Inventory Tag Internal States
  InventoryTagInternalStates,
  InventoryTagInternalStatesForm,
  InventoryTagInternalStatesVersionHistory,

  //Payment Internal States
  PaymentInternalStates,
  PaymentInternalStatesForm,
  PaymentInternalStatesVersionHistory,

  StoreCollectionMarkupVersionHistory,
  StoreCollectionTypeMarkupVersionHistory,
  Markups,
  IncentiveCalculatorForm,
  OderLineItemIncentive,
  JewelleryProduct,
  JewelleryProductForm,
  JewelleryProductVersionHistory,
  IncentiveCalculationResults,

  OrderLineItemDemandVersionHistory,
  PrLineItemVersionHistory,
  PoVersionHistory,
  PoLineItemVersionHistory,

  PurchaseReturn,
  PurchaseReturnForm,

  Grn,
  GrnForm,
  GrnVersionHistory,
  GrnLineItem,
  GrnLineItemForm,
  GrnLineItemVersionHistory,
  OrderDemandLineItem,
  OrderDemandLineItemForm,
  OrderDemandLineItemVersionHistory,

  ASMIncentive,
  ASMIncentiveForm,
  ASMIncentiveVersionHistory,

  PoState,
  PoStateForm,
  PoLineItemState,
  PoLineItemStateForm,
  GrnState,
  GrnStateForm,
  GrnLineItemState,
  GrnLineItemStateForm,
  OrderLineItemDemandState,
  OrderLineItemDemandStateForm,
  OrderLineItemDemandStoneState,
  OrderLineItemDemandStoneStateForm,
  PrState,
  PrStateForm,
  GrnQcState,
  GrnQcStateForm,
  GrnQcStateVersionHistory,

  ServiceChargePo,
  ServiceChargePoForm,
  ServiceChargePoVersionHistory,

  ActivityCalendar,
  ActivityCalendarForm,
  ActivityCalendarVersionHistory,

  //Activity Location
  ActivityLocationMapping,
  ActivityLocationMappingForm,
  ActivityLocationMappingVersionHistory,

  //Calendar
  Calendar,
  CalendarForm,
  CalendarVersionHistory,

  CustomersEnquiries,
  CustomersEnquiriesForm,
  CustomersEnquiriesVersionHistory,

  CustomersEnquiriyRequests,
  CustomersEnquiriyRequestsForm,
  CustomersEnquiriyRequestsVersionHistory,

  //Appointment
  Appointment,
  AppointmentForm,
  AppointmentVersionHistory,

  //Appointment Internal State
  AppointmentInternalStates,
  AppointmentInternalStatesForm,
  AppointmentInternalStatesVersionHistory,

  //Appointment Type
  AppointmentTypes,
  AppointmentTypesForm,
  AppointmentTypesVersionHistory,

  //Appointment Mode
  AppointmentModes,
  AppointmentModesForm,
  AppointmentModesVersionHistory,

  ActivityCollectionMapping,
  ActivityCollectionMappingForm,
  ActivityCollectionMappingEditForm,
  ActivityComponentMapping,
  ActivityComponentMappingForm,
  ActivityComponentMappingEditForm,

  // PO Price
  PoPrice,
  PoPriceForm,
  PoPriceVersionHistory,
  CollectionsProperties,
  CollectionsPropertiesForm,
  CollectionsPropertiesVersionHistory,
  CollectionsUnits,
  CollectionsUnitsForm,
  CollectionsUnitsVersionHistory,

  RelationType,
  RelationTypeForm,
  RelationTypeVersionHistory,
  FraudScore,
  UserCoupon,
  UserCouponForm,
  RepairRequest,
  RepairRequestForm,
  RepairRequestVersionHistory,
  RepairRequestState,
  RepairRequestStateForm,

  ReplacementRequest,
  ReplacementRequestForm,
  ReplacementRequestVersionHistory,
  ReplacementRequestState,
  ReplacementRequestStateForm,

  BankDeposite,
  BankDepositeForm,
  CashLedger,
  CurrencyRates,
  CurrencyHedge,
  CashOrdersListForm,

  TaxSetup,
  TaxSetupForm,
  LogisticsProvider,
  LogisticsProviderForm,
  LogisticsProviderVersionHistory,
  CurrencyRatesForm,

  Vendor,
  VendorForm,
  VendorVersionHistory,
  VendorFormBulkUpdates,

  ShipmentTracking,
  ShipmentTrackingForm,
  ShipmentTrackingVersionHistory,


  //Vendor Feed
  Vendors,
  AddVendors,
  VendorConfig,
  AddVendorConfig,
  FilterStoneData,
  
  //Cron Job
  CronJob,
  AddCron,
  EditCron,
  ShipmentMethods,
  ShipmentMethodsForm,
  ShipmentMethodsVersionHistory,

  LocationLogisticsProviderMaps,
  LocationLogisticsProviderMapsForm,

  //Scheduled And Locking
  Address,
  AddressForm,
  AddressVersionHistory,
  AddressFormBulkUpdates,

  Scheduled,

  // Reports
  BookReport,
  HoldReport,
  CSHoldReport,

  UserCalendar,
  BespokeEnquiries,
  BespokeEnquiriesForm,
  BespokeEnquiriesVersionHistory,

  BespokeQuestionnaires,
  BespokeQuestionnairesForm,
  BespokeQuestionnairesVersionHistory,

  //QC
  QCIn,
  QCInForm,
  QCInVersionHistory,
  QCApproved,
  QCApprovedForm,
  QCApprovedVersionHistory,
  QCReject,
  QCRejectForm,
  QCRejectVersionHistory,

  OrderDemandStoneResults,
  Reconcilation,
  ReconcilationForm,
  InventoryTagReconciliationVersionHistory,
  StockTagReconciliationVersionHistory,
  ShipmentTrackingInternalState,
  ShipmentTrackingInternalStateForm
};


// Return Component Mappings
const ComponentName = ({ name }) => {
  const stringName = name.replace(/\s/g,
    ""); const TagName = componentMap[stringName]; return <TagName />;
};

export default ComponentName;